.quotesText{
    text-align: center;
    vertical-align: middle;
    color:darkslategray;
    font-size: 3vw;
    width: 60%;
    padding-top: 1.5vw;
    padding-left: 3vw;
    padding-bottom: 4vw;

}
.quotes-container{
   

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;

}
.quotesImg{
    flex: basis 100%;
    width: 80vw;
    height: 40vw;
    background-size: contain;
    background-image: url("../../public/images/scroll.png");
    background-position: center center;
    background-repeat:no-repeat;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}