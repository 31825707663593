@font-face { font-family: Chalk; src: url('../components/fonts/chalk.ttf'); } 
.planner{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;

}
.displayContainer{
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: mediumaquamarine;
    width: 25vw;
    padding: 10px;
    margin: 10px;
}
.displayComments{
    color: azure;
    flex-basis: 70%;
}
.displayLikes{
    color: azure;
    flex-basis: 20%;
}
.displayDeleteButton{
    flex-basis: 10%;
}
.signIn{
    text-align: center;
    padding-bottom: 20vw;
    font-size: x-large;
}
input{
    flex: auto;
    flex-direction: column;
    margin: 5px;
}
.chalkBoard{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: rgb(29 70 36);
    border: 10px;
    border-style: solid;
    border-color: rgb(150, 127, 99);
    
}
.displayMessage{
    font-family: Chalk;
    padding: 5px;
    font-size: x-large;
}
.displayTitle{
    font-size: xx-large;
    font-family: Chalk;
    padding: 5px;
}
.displayEventTime{
    font-size:larger;
    font-family: Chalk;
    padding: 5px;
}
.eraseEvent{
    font-size: larger;
    font-family: Chalk;
    padding: 5px;
    background-color: transparent;
    color: white;
    border: none;
}
.modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    width: 85vw;
    background-color: rgb(29 70 36);
    border: 10px;
    border-style: solid;
    border-color: rgb(150, 127, 99);
}
.modalContent{
    justify-content: center;
    display:flex;
    flex-direction: column;
}
.submit{
    font-size: larger;
    font-family: Chalk;
    padding: 5px;
    background-color: transparent;
    color: white;
    border: none;
}
