.theater-group-cards{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    padding-bottom: 60px;
    padding-left: 6vw;
    padding-right:6vw;

}
.theater-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 40%;

    
    padding: 10px;
}
.modal-button{
    width: 100%;
    flex-basis: 70%;
    background-color:black;
}
.theaterText{
    flex-basis: 30%;
    flex-direction: column;
}
.theater-button-img{
    width: 100%;
    border: none;
}
.theater-card-title{
    flex: 1;
    color: azure;
    font-size: 2vw;
}
.theater-card-des{
    flex: 3;
    color: azure;
    font-size: 1.5vw;
}
.overlay{
    background-color: rgba(0, 0, 0, 0.5);
    position:fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    top: 0%;
    left: 0%;

}
.modal-container{
    position: fixed;
    width: 80vw;
    height: 45vw;
    top: 9vh;
    left: 9vw;
    transform: translate(-100%,-100);
    z-index: 2;
}
.closeBtn{
    text-align: right;
    color: aliceblue;
}

