.backgroundImage {
    background-color: black;
    background-image: url("stars.jpg");
    background-size: cover;
    background-attachment: fixed;
    margin: -1vh;
    background-position: center center;
    background-repeat:no-repeat;
    min-height: 100vh;

}
.headerPhoto{
    display: block;
    text-align: center;
    max-width: 100%;
}
.test{
    font-size: 40px;
    color: black;
}
.go-home-button-img{
    background-color: black;  
    width: 12vw;
    height: 8vw;
    border: none;
    margin: none;

}
.backButton{

    margin: none;
    background-color: transparent;
    padding-right: 13vw;
    padding-bottom: 2.5vw;
    border: none;

}
.buttonContainer{
    margin: none;
    border: none;
    width: 100%;
    text-align: right;
    
}
.calender{
display: flexbox;
flex-direction: column;
padding: 8vw;
}
.secretB{
    background-color: transparent;
    border: none;
}
.secretO{
    display: flex;
    align-items: center;
    align-self: center;
}

@media (min-width: 320px) and (max-width: 800px) {
    .backgroundImage{
        background-image: url("starsflip.jpg");
        background-size: cover;

    }

}