.card-group-container{
    flex: 1;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: flex;
}
.cards_container {
    flex: 1;
    display: flex;
    flex-flow: row;
    max-width: 1000px;
    padding: 10px;
  }
  .card-text{
    flex: 1;
    flex-direction: column;
    padding: 2px;
    color: azure;
  }
  .card-title{
    font-size: 36px;
  }
  .card-des{
    font-size: 20px;
  }
  .home-buttons {
    background-color: transparent;
    border: none;
  }
  .home-button-img{
    max-width: 250px;
  }

  @media (min-width: 320px) and (max-width: 800px) {
    .cards_container {
      flex: 1;
      display: flex;
      flex-flow: row;
      max-width: 100%;
    }
    
    .card-text{
      flex: 1;
      flex-direction: column;
      padding: 2px;
      color: azure;
    }
    .card-title{
      font-size: 25px;
      flex-basis: 25%;
    }
    .card-des{
      font-size: 10px;
      flex-basis: 75%;
    }
    .home-buttons {
      background-color: transparent;
      border: none;
      flex-basis: 32%;
    }
    .home-button-img{
      max-width: 100%;
    }
    }
    